import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {
  Anchor,
  AnchorLink,
  Header,
  HeaderPicture,
  SiteGoal,
} from "../components/HeaderSection"
import TextHighlight from "../components/TextHighlight"
import Button from "../components/Button"
import { Link } from "gatsby"
import Space from "../components/Space"
import scrollTo from "gatsby-plugin-smoothscroll"
import {
  FirstRole,
  SecondRole,
} from "../components/BestPracticeStyled"
import styled from "styled-components"
import MindsetImg from "../img/mindset/mindset-visualisation.svg"
import ProcessImg from "../img/mindset/process-visualisation.svg"
import AwareIcon from "../icons/aware-icon.svg"
import SufficientIcon from "../icons/sufficient-icon.svg"
import ResponsibleIcon from "../icons/responsible-icon.svg"
import CircularIcon from "../icons/circular-icon.svg"
import { BiggerBody, MindsetPT, MindsetPTBackground, SmallIcon } from "./index"
import {
  CATSubheadline,
  CATUppercase,
  PTButton,
  PTHeadline,
} from "../components/CTASection"
import MindsetHeaderImg from "../img/mindset/mindset-header-img.svg"

export const PrincipleBackground = styled.div`
  z-index: -1;
  grid-column: 1 / span 5;
  grid-row: 7/10;
  background-color: ${props => props.theme.colors.shapes};
  height: 740px;
  border-radius: 0 280px 0 0;
  @media screen and (max-width: 90rem) {
    display: none;
  }
`
export const PrincipleHeadline = styled.h3`
  padding-top: 11rem;
  grid-column: 2 / span 7;
  grid-row: 7;

  @media screen and (max-width: 90rem) {
    padding-top: 0;
    grid-column: 2 / span 6;
  }
`
export const PrincipleDescription = styled.h4`
  grid-column: 2 / span 4;
  padding-top: 0;
  grid-row: 9;
  font-size: 1.5em;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: right;
  color: ${props => props.theme.colors.tertiary};
  text-transform: uppercase;

  @media screen and (max-width: 90rem) {
    margin-top: 25px;
    margin-bottom: 10px;
    grid-column: 2 / span 3;
    grid-row: 8;
    text-align: left;
    font-size: 1.1em;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 6;
    text-align: left;
    font-size: 1em;
  }
`
export const Principles = styled.h5`
  padding-bottom: 0.3em;
  color: ${props => props.theme.colors.blue};
  text-transform: uppercase;
  border-bottom: 4px solid ${props => props.theme.colors.blue};
  text-underline-offset: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const FourthRole = styled.section`
  grid-column: 6 / span 4;
  grid-row: 9;
  margin-top: 50px;
  margin-bottom: 50px;

  @media screen and (max-width: 90rem) {
    margin-top: 25px;
    margin-bottom: 25px;
    grid-column: 5 / span 3;
    grid-row: 9;
    text-align: left;
  }
  @media screen and (max-width: 37rem) {
    margin-top: 10px;
    margin-bottom: 10px;
    grid-column: 2 / span 6;
    grid-row: 12;
    text-align: left;
  }
`
export const FifthRole = styled.section`
  grid-column: 10 / span 4;
  grid-row: 9;
  margin-top: 50px;
  margin-bottom: 50px;

  @media screen and (max-width: 90rem) {
    margin-top: 25px;
    margin-bottom: 25px;
    grid-column: 2 / span 3;
    grid-row: 10;
    text-align: left;
  }
  @media screen and (max-width: 37rem) {
    margin-top: 10px;
    margin-bottom: 10px;
    grid-column: 2 / span 6;
    grid-row: 13;
    text-align: left;
  }
`
const PrincipleConclusion = styled.h4`
  grid-column: 6 / span 8;
  grid-row: 10;
  padding-top: 0;
  text-align: right;
  @media screen and (max-width: 90rem) {
    grid-column: 5 / span 3;
    grid-row: 10;
    margin-top: 25px;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 6;
    grid-row: 14;
    margin-top: 0;
    text-align: left;
  }
`
export const MindsetTextSection = styled.section`
  grid-column: 7 / span 6;
  grid-row: 12;
  @media screen and (max-width: 90rem) {
    grid-column: 5 / span 3;
    grid-row: 13;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 2 / span 6;
    grid-row: 18;
    padding-top: 2rem;
  }
`
export const MindsetPicSection = styled.div`
  margin-top: 100px;
  position: relative;
  grid-column: 2 / span 5;
  padding-right: 2vw;
  grid-row: 12;
  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 3;
    grid-row: 13;
    margin: 100px 6vw 0 6vw;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 2 / span 6;
    grid-row: 16;
    margin: 0 6vw 0 6vw;
  }
`
const CreditText = styled.p`
  position: absolute;
  bottom: 0;
  font-size: 0.9em;
  @media screen and (max-width: 63rem) {
    display: none;
  }
`
const QList = styled.ul`
  color: ${props => props.theme.colors.green};
  margin-left: 50px;
  @media screen and (max-width: 90rem) {
    margin-left: 35px;
  }
  @media screen and (max-width: 40rem) {
    margin-left: 25px;
  }
`
const QListElement = styled.li`
  margin-top: 18px;
  @media screen and (max-width: 90rem) {
    margin-top: 13px;
  }
  @media screen and (max-width: 63rem) {
    margin-top: 13px;
  }
`
const BoldCreditText = styled.line`
  font-family: "Archivo Black", sans-serif;
  text-transform: uppercase;
`
const CreditTextSection = styled.section`
  visibility: hidden;
  @media screen and (max-width: 63rem) {
    padding: 2rem 0;
    font-size: 0.8em;
    text-align: right;
    visibility: visible;
    grid-column: 2 / span 6;
    grid-row: 17;
  }
`

export const ProcessTextSection = styled.section`
  grid-column: 2 / span 6;
  grid-row: 15;
  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 3;
    grid-row: 20;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 2 / span 6;
    grid-row: 21;
    padding-top: 2rem;
  }
`
export const ProcessPicSection = styled.div`
  position: relative;
  grid-column: 8 / span 6;
  grid-row: 15;
  margin: 200px 5.5vw 0 5.5vw;
  @media screen and (max-width: 90rem) {
    grid-column: 5 / span 3;
    grid-row: 20;
    margin: 200px 8vw 0 8vw;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 2 / span 6;
    margin: 0 13vw 0 13vw;
  }
`

export default function Mindset() {
  return (
    <Layout>
      <Seo
        title={"Mindset – start with the way you think"}
        description={
          "Introduction to a solution proposal on how to reduce a websites co2 emission by using a wholistic sustainable design process."
        }
        keywords={[
          "Make Web Sustainable",
          "Sustainable Web Design",
          "Mindset",
          "Planet centric design",
          "Environment centric design",
        ]}
      />
      <Header>
        <div>
          <h1>
            <TextHighlight colorValue={"blue"}>Start</TextHighlight>
            <br />
            With the way
            <br />
            you think.
          </h1>
          <h2>
            <TextHighlight colorValue={"blue"}>aware</TextHighlight> –{" "}
            <TextHighlight colorValue={"blue"}>sufficient</TextHighlight> –{" "}
            <TextHighlight colorValue={"blue"}>responsible</TextHighlight> –{" "}
            <TextHighlight colorValue={"blue"}>circular</TextHighlight>
          </h2>
          <AnchorLink onClick={() => scrollTo("#mindset-goal")}>
            <Button primaryBlue alignLeft>
              Please tell me more.
            </Button>
          </AnchorLink>
          <Link to="/">
            <Button secundaryBlue alignRight>
              Make web sustainable?
            </Button>
          </Link>
        </div>
      </Header>
      <HeaderPicture><img src={MindsetHeaderImg} alt={"Header picture of a desktop with green screen"}/></HeaderPicture>
      <Anchor id={"mindset-goal"} />
      <SiteGoal>
        <h2>
          Introduction to a sustainable mindset with four key sustainable design
          principles and a design process based on them.
        </h2>
      </SiteGoal>
      <PrincipleBackground />
      <PrincipleHeadline>
        Core <TextHighlight colorValue={"blue"}>sustainable</TextHighlight>{" "}
        design principles
      </PrincipleHeadline>
      <PrincipleDescription>
        They provide the framework for the team. And act as an orientation aid
        so that decisions can be made quicker within the team.
      </PrincipleDescription>
      <FirstRole>
        <Principles>
          Aware
          <SmallIcon src={AwareIcon} width={"60px"} alt={"Small icon of a light bulb"}/>
        </Principles>
        <p>
          It is important to be aware of the effects of your own actions and
          decisions. Because you can't actively change anything that you are not
          aware of.
        </p>
      </FirstRole>
      <SecondRole>
        <Principles>
          Sufficient
          <SmallIcon src={SufficientIcon} width={"60px"} alt={"Small icon of a small circle in a big circle"}/>
        </Principles>
        <p>
          Sufficient is not the same as renunciation. It means saving energy and
          resources when possible. Ask yourself the following question: "Does
          this add value?" When in doubt, leave it out.
        </p>
      </SecondRole>
      <FourthRole>
        <Principles>
          Responsible
          <SmallIcon src={ResponsibleIcon} width={"60px"} alt={"Small icon of a heart"}/>
        </Principles>
        <p>
          Responsibility is something positive, because those who take
          responsibility have the opportunity to bring about positive change.
        </p>
      </FourthRole>
      <FifthRole>
        <Principles>
          Circular
          <SmallIcon src={CircularIcon} width={"60px"} alt={"Small icon of a circle"}/>
        </Principles>
        <p>
          Practice goes before perfection. This cycle is important because you always
          find yourself back at the beginning but this time with more knowledge and
          experience than before.
        </p>
      </FifthRole>
      <PrincipleConclusion>
        <TextHighlight colorValue={"blue"}>
          Feel free to define your own sustainability principles within the team
          on a project-specific basis.
        </TextHighlight>
      </PrincipleConclusion>
      <Space big mEleven />
      <MindsetPicSection>
        <img src={MindsetImg} width={"100%"} height={"auto"} alt={"A visualisation of the make web sustainable mindset"}/>
        <CreditText>
          <TextHighlight colorValue={"grey"}>
            The mindset visualization and subsequent process is a continuation
            of:
          </TextHighlight>
          <br /> <br />
          <BoldCreditText>
            planet centric design
            <br />
          </BoldCreditText>
          @Vincit
          <br /> <br />
          <BoldCreditText>
            Planet Centric Design - How hard can it be?
            <br />
          </BoldCreditText>
          @Anton Schubert
          <br /> <br />
          <BoldCreditText>
            The time for Environment-Centered Design has come
            <br />
          </BoldCreditText>
          @Monika Sznel
          <br /> <br />
          <BoldCreditText>
            The Circular Design Guide
            <br />
          </BoldCreditText>
          @Ellen MacArthur Foundation & IDEO
        </CreditText>
      </MindsetPicSection>
      <CreditTextSection>
        <TextHighlight colorValue={"grey"}>
          The mindset visualization and subsequent process is a continuation of:
        </TextHighlight>
        <br /> <br />
        <BoldCreditText>
          planet centric design
          <br />
        </BoldCreditText>
        @Vincit
        <br /> <br />
        <BoldCreditText>
          Planet Centric Design - How hard can it be?
          <br />
        </BoldCreditText>
        @Anton Schubert
        <br /> <br />
        <BoldCreditText>
          The time for Environment-Centered Design has come
          <br />
        </BoldCreditText>
        @Monika Sznel
        <br /> <br />
        <BoldCreditText>
          The Circular Design Guide
          <br />
        </BoldCreditText>
        @Ellen MacArthur Foundation & IDEO
      </CreditTextSection>
      <MindsetTextSection>
        <h3>
          Mindset{" "}
          <TextHighlight colorValue={"blue"}>Visualisation</TextHighlight>
        </h3>
        <h4>
          It is important to focus on a specific mindset at the beginning of a
          project.
        </h4>
        <BiggerBody>
          Within Design Thinking an useful solution is one that is
          <br />
          <TextHighlight colorValue={"boldPrimary"}> DESIRABLE </TextHighlight>
          <TextHighlight colorValue={"grey"}>
            – the users will like it
            <br />
          </TextHighlight>
          <TextHighlight colorValue={"boldPrimary"}>
            {" "}
            VIABLE{" "}
          </TextHighlight>{" "}
          <TextHighlight colorValue={"grey"}>
            – it will generate enough profit
            <br />
          </TextHighlight>
          <TextHighlight colorValue={"boldPrimary"}>
            {" "}
            FEASIBLE{" "}
          </TextHighlight>{" "}
          <TextHighlight colorValue={"grey"}>– we can develop it</TextHighlight>
          .<br /> <br />
          If you zoom out and include the four defined sustainability design
          principles <br />
          <TextHighlight colorValue={"boldBlue"}>AWARE</TextHighlight> –{" "}
          <TextHighlight colorValue={"boldBlue"}>SUFFICIENT</TextHighlight> –{" "}
          <TextHighlight colorValue={"boldBlue"}>RESPONSIBLE</TextHighlight> –{" "}
          <TextHighlight colorValue={"boldBlue"}>CIRCULAR</TextHighlight> the
          solution should also comply with these principles and thus be useful to everyone.
          Not only to the consumer, the seller and the manufacturer, but also
          resource sufficient and strive not to harm any life on this planet,
          including plants, rivers, air, etc.
          <br /> <br />
          <TextHighlight colorValue={"boldGrey"}>THEREFORE </TextHighlight>
          <TextHighlight colorValue={"grey"}>
            single-use plastic bags are desirable, feasible and viable.{" "}
            <TextHighlight colorValue={"boldGrey"}>BUT</TextHighlight> not
            sufficient, because the user does not need several single-use
            plastic bags, if he uses a cloth bag instead. It is not responsible
            to produce plastic bags because they end up as waste and they are
            not circular because they are not recyclable. Therefore, single-use
            plastic bags would not have been invented if a sustainable design
            process had been used at the time.{" "}
          </TextHighlight>
        </BiggerBody>
        <h4>
          <TextHighlight colorValue={"blue"}>
            Other, already defined sustainability design principles can be added
            to the outer ring.
          </TextHighlight>
        </h4>
      </MindsetTextSection>
      <Space big fourteen />
      <ProcessPicSection>
        <img src={ProcessImg} width="100%" height="auto" alt={"A visualisation of the make web sustainable process"}/>
      </ProcessPicSection>
      <ProcessTextSection>
        <h3>
          <TextHighlight colorValue={"blue"}>Process </TextHighlight>{" "}
          Visualisation
        </h3>
        <h4>
          simple - expandable - holistic
        </h4>
        <BiggerBody>
          This design process is based on the above mindset and will guide you
          through the design and development process of a sustainable website.
          <br /> <br />
          <TextHighlight colorValue={"boldGreen"}>UNDERSTAND </TextHighlight><br />
          <QList>
            <QListElement>
              How can we prepare ourselves to work with sustainability?
            </QListElement>
            <QListElement>
              How can we understand the environmental impact of our actions and our role in the solution?
            </QListElement>
            <QListElement>
              What is the problem to be solved?
            </QListElement>
          </QList>
          <br /> <br />
          <TextHighlight colorValue={"boldTurquoise"}>IDEATE</TextHighlight><br />
          <QList>
            <QListElement>
              How can we develop ideas that are based on sustainable principles?
            </QListElement>
          </QList>
          <br /> <br />
          <TextHighlight colorValue={"boldBlue"}>CREATE</TextHighlight><br />
          <QList>
            <QListElement>
              How can we define what sustainability means in our implementation?
            </QListElement>
          </QList>
          <br /><br />
          <TextHighlight colorValue={"boldViolet"}>RELEASE</TextHighlight><br />
          <QList>
            <QListElement>
              How do we manage to support others with our project?
            </QListElement>
            <QListElement>
              What can we learn from this iteration?
            </QListElement>
            <QListElement>
              How can we maintain the added value of the website over long term?
            </QListElement>
          </QList><br />
        </BiggerBody>
        <h4>
          This process was used to create this website and can be used as a
          <TextHighlight colorValue={"blue"}> blueprint</TextHighlight> or a
          starting point for your process.
        </h4>
      </ProcessTextSection>
      <Space big mSixteen />
      <MindsetPTBackground />
      <MindsetPT>
        <CATUppercase>
          <TextHighlight colorValue={"violet"}>Up Next</TextHighlight>
        </CATUppercase>
        <PTHeadline>
          You want to become{" "}
          <TextHighlight colorValue={"violet"}>active</TextHighlight>?
        </PTHeadline>
        <CATSubheadline>
          Take a look at best practices that show you how to reduce CO2
          emissions of websites.
        </CATSubheadline>
        <PTButton>
          <Link to="/best-practices">
            <Button catPrimaryViolet alignLeft>
              Explore Practices
            </Button>
          </Link>
        </PTButton>
      </MindsetPT>
    </Layout>
  )
}
